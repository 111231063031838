div.column-layout,
form.column-layout {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	fieldset {
		width: 100%;
	}
	.column {
		width: 100%;
	}
	@media only screen and (min-width: $largeScreen) {
		.column {
			display: inline-block;
			width: 49%;
		}
	}
}

fieldset.column-layout {
	width: 100%;
	@media only screen and (min-width: $largeScreen) {
		width: 49%;
	}
}

.column-layout-three {
	display: flex;
	flex-wrap: wrap;
	fieldset {
		padding: 10px;
		width: 100%;
		@media only screen and (min-width: $largeScreen) {
			width: 40%;
			&:first-of-type {
				width: 20%;
			}
		}
		@media only screen and (min-width: $extraLargeScreen) {
			width: 33%;
			&:first-of-type {
				width: 33%;
			}
		}
	}
}

.flatpickr-calendar .flatpickr-day {
	@include rounded;
}

.mainform {
	.profile-photos {
		display: flex;
		flex-flow: row wrap;

		label {
			cursor: pointer;
		}

		.profile-img {
			padding: 0 15px;
			padding-bottom: 15px;
			display: flex;
			flex-direction: column;

			.profile-name {
				padding-top: 5px;
				text-align: center;
				word-break: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 120px;
			}

			.form-row {
				display: none;
			}
		}
	}

	hr {
		margin-bottom: 10px;
		border: none;
		border-top: 1px solid;
	}

	fieldset {
		margin-bottom: 15px;
		min-width: 0;

		.organisation-image {
			margin: 10px;
			margin-bottom: 20px;
		}

		.dynamicProfileChildren {
			padding-left: 20px;
		}

		.card-progress {
			@include rounded;
			width: 120px;
			height: 120px;

			.card-top {
				@include rounded;
				.card-img-container {
					padding: 5px 0 0 5px;

					.card-icon,
					.card-icon-img {
						width: 110px;
						height: 110px;
						i {
							position: relative;
							left: -5px;
							top: -5px;
						}
					}
				}
			}
		}

		.card-img-container,
		.card-icon-img {
			width: 120px;
			height: 120px;
		}

		.card-top,
		.card-icon-img {
			font-size: 0.9em;
			width: 120px;
			position: relative;
			margin-bottom: 10px;

			.card-icon {
				width: 120px;
				height: 120px;
				text-align: center;
				i {
					font-size: 4.2em;
					line-height: 120px;
					width: 120px;
				}
			}

			.card-status {
				width: 26px;
				height: 26px;

				text-align: center;
				position: absolute;
				right: 0;
				top: 0;
				font-size: 1.2em;
				line-height: 21px;
				border-radius: 50%;
				border: 2px solid #fff;

				.card-status-icon {
					padding-top: 0.14em;
					padding-left: 0.05em;

					&.card-icon-progress {
						font-size: 0.8em;
					}
				}
			}
		}

		legend,
		h2,
		h3 {
			font-family: $font;
			font-size: 1.4em;
			margin-bottom: 10px;
			width: 100%;
		}

		h3,
		h2 {
			margin-top: 25px;
		}

		h3 {
			font-size: 1.2em;
		}

		h2:first-child {
			margin-top: 0px;
		}

		div.form-row-remove {
			display: flex;

			&.form-row-inline {
				padding-bottom: 5px;
			}

			i {
				margin-top: auto;
				margin-bottom: auto;
			}
		}

		.form-spacer {
			margin-bottom: 20px;
		}

		div.form-row-combined {
			display: flex;
			.form-row {
				padding-left: 10px;
			}
			.form-row:first-child {
				padding-left: 0px;
			}
			i {
				margin-left: 5px;
			}
		}

		div.form-row {
			display: flex;
			flex-direction: column-reverse;
			width: 100%;
			margin-bottom: 20px;

			&.form-row-slider {
				flex-direction: row;

				.form-slider-value {
					width: 40px;
					font-size: 1.2em;
					font-weight: bolder;
					text-align: right;
				}

				.form-slider-slider {
					width: 100%;
				}
			}

			label {
				display: block;
				margin-bottom: 5px;
			}

			.form-hr {
				margin-top: 10px;
			}

			input,
			textarea,
			div.formfield {
				font-family: $text;
				width: 100%;
				padding: 5px 0;
				background-color: transparent;
				outline: none;
				border: 0px solid;
				border-style: solid;
				border-bottom-width: 1px;
				font-size: 1em;
				height: 29px;

				a {
					text-decoration: none;
				}
			}

			.form-search {
				max-width: 200px;
			}

			.form-select {
				display: flex;
				margin-bottom: 0px;
				flex-direction: column-reverse;
				position: relative;
				width: 100%;

				&:after {
					content: '\f0dc';
					font-family: 'Font Awesome 5 Free';
					position: absolute;
					right: 4px;
					bottom: 6px;
					pointer-events: none;
				}
			}

			.form-multiselect {
				> div {
					& > label {
						@include no-select;
						display: none;
						cursor: pointer;
					}

					& > input {
						display: none;
						&:checked + label {
							display: inline-block;
						}
					}
				}
				&.readonly {
					> div > label {
						cursor: default;
					}
				}
			}

			select {
				width: 100%;
				border: 0;
				border-style: solid;
				border-bottom-width: 1px;
				-webkit-appearance: none;
				font-size: 1em;
				background-color: transparent;
				-webkit-border-radius: 0px;
				padding: 5px 0;
			}

			textarea {
				overflow: hidden;
				resize: none;
			}
		}
		.form-slider {
			background: red;
		}
		.form-switch {
			margin-top: 4px;
			position: relative;
			display: inline-block;
			width: 36px;
			height: 20px;

			input {
				opacity: 0;
				width: 0;
				height: 0;

				&:checked + .form-slider {
					&:before {
						-webkit-transform: translateX(14px);
						-ms-transform: translateX(14px);
						transform: translateX(14px);
						content: '\f00c';
						font-size: 0.6em;
						color: black;
						padding: 1px;
						font-family: 'Font Awesome 5 Free';
					}
				}
			}

			.form-slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: gray;
				border-radius: 20px;

				&:before {
					position: absolute;
					content: '\f068';
					font-size: 0.6em;
					padding-left: 2px;
					padding-top: 1px;
					font-family: 'Font Awesome 5 Free';
					height: 12px;
					width: 12px;
					left: 4px;
					bottom: 4px;
					background-color: white;
					border-radius: 50%;
				}
			}
		}
	}
}

.form-row.readonly {
	.jodit_toolbar,
	.jodit_statusbar {
		display: none !important;
	}
}

.jodit_container {
	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	ul {
		list-style-type: disc;
		list-style-position: inside;
	}
	ol {
		list-style-type: decimal;
		list-style-position: inside;
	}
	ul ul,
	ol ul {
		list-style-type: circle;
		list-style-position: inside;
		margin-left: 15px;
	}
	ol ol,
	ul ol {
		list-style-type: lower-latin;
		list-style-position: inside;
		margin-left: 15px;
	}

	h1 {
		font-size: 3em;
	}

	h2 {
		font-size: 2em;
		border-bottom: 0 !important;
	}

	h3 {
		font-size: 1.8em;
	}

	h4 {
		font-size: 1.4em;
	}
}
