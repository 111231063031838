.reports {
	.report-buttons {
		display: flex;
		column-gap: 10px;
		input.form-date-range {
			width: 200px;
			padding: 5px 0;
			background-color: transparent;
			outline: none;
			border: 0;
			font-size: 1em;
			height: 29px;
		}
	}
}