/* Dropdown */

.dropdown {
	@include no-select;
	display: inline-block;
	position: relative;


	.dd-button {
		cursor: pointer;
	}
	
	.dd-input {
		display: none;
		
		&:checked + .dd-menu {
			display: block;
		} 
		
		+ .dd-menu {
			display: none;
			z-index: 100000;
		} 
	}
	
	.dd-menu {
		position: absolute;
		overflow-y: auto;
		max-height: 80vh;
		max-width: 80vw;
		top: 100%;
		border-radius: 4px;
		padding: 0;
		margin: 2px 0 0 0;
		list-style-type: none;

		&.dd-right {
			right: 0;
		}

		ul {
			margin-left: 10px;	
		}
		
		li {
			
			&.disabled {
				text-decoration: line-through;
			}
			
			white-space: nowrap;
			padding: 0;
			cursor: pointer;
			text-align: left;
			font-weight: 300;
			font-family: $font;

			&.dd-seperator {
				cursor: default;
				border-bottom: 1px solid;
			}

			
			div {
				text-overflow: ellipsis;
				white-space: nowrap;
				padding: 10px 20px;
				overflow: hidden;
			}
						
			a {
				display: block;
				margin: -10px -20px;
				padding: 10px 20px;
			}
					
			&.divider {
				padding: 0;
			}
			
			i {
				pointer-events: none;
				padding: 0px 10px 0px 0px;
				width: 30px;
			}
		}
	}
}