$extraLargeScreen: 1500px;
$largeScreen: 900px;
$smallScreen: 500px;

@import 'include/fonts';

@import 'include/reset';
@import 'mixins/noselect';
@import 'mixins/rounded';

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

* {
	padding: 0;
	margin: 0;
}

*:focus {
	outline: 0;
}

body {
	font-family: $text;
}

.customScrollBar {
	::-webkit-scrollbar {
		position: absolute;
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-thumb {
		background: #666;
		border-radius: 20px;
	}

	::-webkit-scrollbar-track {
		background: #ddd;
		border-radius: 20px;
	}

	::-webkit-scrollbar-corner {
		height: 6px;
		width: 6px;
		background: #ddd;
	}
}

main {
	padding: 10px 10px 10px 10px;
	min-height: 100vh;
}

ul {
	list-style: none;
}

.link {
	cursor: pointer;
}

.inline-file {
	margin-bottom: 10px;
}

#loader {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;

	backdrop-filter: blur(1px);
	-webkit-backdrop-filter: blur(1px);

	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	transition: 0.4s;
	opacity: 1;

	i {
		font-size: 4em;
	}
}

#debug {
	font-size: 12px;
	margin: 5px;
	display: inline-block;
	border: 1px solid #681d1d;
	background-color: #aa3838;
	color: #fff;
	border-radius: 5px;
	li {
		padding: 5px 10px;
		border-bottom: 1px solid #681d1d;
		&:last-child {
			border: none;
		}
	}
}

@media only screen and (max-width: $smallScreen) {
	.swal2-header {
		font-size: 0.7em;
	}
}

@import 'include/fonts';

$sideMenuWidth: 240px;
$headerHeight: 50px;

$largeScreen: 768px;

.hide-web {
	display: none;
}

header {
	display: flex;
	position: fixed;
	top: 0;
	height: $headerHeight;
	width: 100%;
	padding: 10px;
	z-index: 1;
	transition: all ease-in-out 200ms;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;
	align-items: center;

	.toggle {
		@include no-select;
		padding: 0px 10px 0px 10px;
		display: inline-block;
		cursor: pointer;
	}

	.navigationButtons {
		flex: 1;
		display: flex;
		justify-content: flex-start;
	}

	#backButton {
		padding-left: 5px;
		cursor: pointer;
	}

	.title {
		line-height: 1.1em;
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		h1 {
			overflow: hidden;
			padding: 0 0 0 5px;
			white-space: nowrap;
			font-family: $font;
			text-overflow: ellipsis;
		}
	}

	.actions {
		display: flex;
		flex: 1;
		justify-content: flex-end;

		#mainaction {
			padding: 0;
			div {
				max-width: 33vw;
				padding: 15px 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		i {
			padding: 0px 10px 0px 10px;
		}

		> * {
			cursor: pointer;
			padding: 15px 5px;
		}

		#search {
			input[type='text'] {
				width: 100%;
				height: 36px;
				font-size: 1.1em;
				padding-left: 19px;
				padding-right: 19px;
			}

			label {
				cursor: pointer;
				width: 100%;
				height: 36px;
				display: block;
				padding: 10px 20px;
			}

			input[type='radio'] {
				display: none;
			}

			input[type='radio']:checked + .fa-circle:before {
				content: '\f058';
			}
		}
	}
}

main {
	padding: $headerHeight + 10px 10px 10px 10px;
	position: relative;
	z-index: 0;
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;
}

.page-wrap {
	overflow-x: hidden;
	overflow-y: auto;
	font-family: $text;
}

.sidebar {
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: -$sideMenuWidth;
	width: $sideMenuWidth;
	z-index: 1;

	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	transition: 0.6s;

	white-space: nowrap;

	li {
		@include no-select;
		display: block;
		-webkit-font-smoothing: antialiased;

		list-style: none;

		&.active,
		&:hover {
			transition: 300ms;
		}

		i {
			text-align: center;
			width: 30px;
		}

		> div,
		> label > div {
			cursor: pointer;
			padding: 15px 10px 15px 10px;
		}
	}

	.split {
		display: inline-flex;
		width: 190px;

		div {
			&.startSplit {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				flex-shrink: 1;
			}

			&.endSplit {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 190px;
				flex-shrink: 0;
			}
		}
	}

	.userinformation {
		padding: 15px;
		cursor: default;

		.maincontainer {
			display: flex;
			.imagecontainer {
				img {
					@include rounded;
					height: 55px;
				}
			}

			.namecontainer {
				padding: 15px 5px 5px 8px;

				.firstName {
					display: block;
				}

				.lastName {
					display: block;
				}
			}
		}

		&.active,
		&:hover {
			transition: none;
		}
	}
}

#side {
	display: none;
	&:checked + .page-wrap {
		.sidebar {
			transform: translateX($sideMenuWidth);
			li {
				font-size: 16px;

				i.quick-add {
					font-size: 0.7em;
					position: absolute;
					padding-top: 2px;
					right: 5px;
				}

				&.sidemenu-sub {
					position: relative;

					input[type='checkbox'] {
						display: none;
					}

					> div {
						padding: 0;
						border: 0;

						i.submenu-opened,
						i.submenu-closed {
							position: absolute;
							right: 5px;
							top: 14px;
							pointer-events: none;
						}

						i.submenu-opened {
							display: none;
						}
						i.submenu-closed {
							display: block;
						}

						ul {
							display: none;
							li > div {
								padding-left: 25px;
							}
						}

						i.quick-add {
							font-size: 0.7em;
							position: absolute;
							padding-top: 2px;
							right: 5px;
						}
					}

					.split {
						padding-left: 5px;
					}

					input:checked + div {
						ul {
							display: block;
						}
						i.submenu-opened {
							display: block;
						}
						i.submenu-closed {
							display: none;
						}
					}
				}
			}
		}
		@media only screen and (min-width: $largeScreen) {
			main {
				padding-left: ($sideMenuWidth + 10px);
			}
			#debug {
				margin-left: ($sideMenuWidth + 10px);
				max-width: calc(100% - 260px);
			}
			#profile {
				transform: translateX($sideMenuWidth);
			}
		}
		header {
			padding-left: $sideMenuWidth;
		}
	}
}

.swal2-toast-shown .swal2-container.swal2-top-end {
	top: 50px !important;
	opacity: 0.8;
	pointer-events: none;
}

@media only screen and (max-width: $largeScreen) {
	.hiddenOnSmallScreen {
		display: none;
	}
}

@media only screen and (max-width: $smallScreen) {
	.swal2-header {
		font-size: 0.7em;
	}
}

@import 'include/network';
@import 'include/stickyheader';
@import 'include/dropdown';
@import 'include/form';
@import 'include/card';
@import 'include/button';
@import 'include/panel';
@import 'include/reports';
@import 'include/license';

$backgroundColor: #1e1e1e;
$rowOdd: #323232;
$rowEven: #1a1a1a;
$lineColor: #212121;
$baseColor: #333;
$highlightColor: #fb6c25;
$highlightHoverColor: $lineColor;
$textColor: #fff;
$labelColor: gray;
$textColorDisabled: #888;

$headerColor: #191919;
$headerTextColor: #fff;
$headerDisabledTextColor: #ddd;

$sideMenuColor: $headerColor;
$sideMenuColorTextColor: $headerTextColor;

$dropdownBorderColor: $headerColor;
$dropdownBackgroundColor: lighten($backgroundColor, 10);
$dropdownTextColor: $headerTextColor;

$cardBackground: gray;
$cardBackgroundHover: lightgray;

$shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

$analyserWindowBackground: #353535;
$analyserStrokeColor: #000;

@import 'include/skin';
