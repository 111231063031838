@keyframes heartbeat {
	0% {
		transform: scale( .90 );
	}
	20% {
		transform: scale( 1 );
	}
	40% {
		transform: scale( .90 );
	}
	60% {
		transform: scale( 1 );
	}
	80% {
		transform: scale( .90 );
	}
	100% {
		transform: scale( .90 );
	}
}


#network-overlay {
	display: none; 
	z-index: 10001;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0,0,0,0.8);
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	
	> div {
		color: #fff;
		max-width: 450px;
		margin: 10px;
		
		.network-overlay-icon {
			color: darkred;
			animation: heartbeat 1s infinite;
			font-size: 6em;
		}
		
		h2 {
			font-size: 2em;
			font-weight: 200;
		}
		
		p {
			margin-bottom: 10px;
		}
	}
}