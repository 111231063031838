.license {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	margin-bottom: 10px;
	font-size: 1.1em;

	> * {
		margin-bottom: 10px;
	}
	.license-code-date {
		display: flex;
		column-gap: 10px;
		font-family: monospace;
		flex-direction: column;
		@media only screen and (min-width: 2000px) {
			flex-direction: row;
		}
	}

	.license-code {
		.form-row {
			flex-direction: row;
			column-gap: 10px;

			.form-switch .form-slider {
				background-color: darkred;
			}
			.form-switch input:checked:not(:disabled) + .form-slider {
				background-color: darkgreen;
			}
		}
	}

	.license-allowedDevices {
		border-radius: 5px;
		display: inline-block;
		background-color: #fb6c25;
		margin-right: 10px;
		padding: 0 5px;
		color: #fff;
	}

	.license-tags {
		* {
			font-family: monospace;
			text-align: center;
			font-size: 0.8em;
			border-radius: 5px;
			display: inline-block;
			background-color: #fff;
			font-weight: bold;
			margin-right: 10px;
			padding: 5px;
			&.license-tag-type {
				color: #fb6c25;
			}
			&.license-tag-revoked {
				color: red;
			}
			&.license-tag-expired {
				color: darkred;
			}
			&.license-tag-unpaid {
				color: red;
			}
			&.license-tag-registered {
				color: darkgreen;
			}
			&.license-tag-beta {
				color: green;
			}
			&.license-tag-student {
				color: blue;
			}
			&.license-tag-demo {
				color: darkblue;
			}
		}
	}
}
.hide-default-status .license .license-tags {
	.license-tag-type,
	.license-tag-registered {
		display: none; // make the overview a bit cleaner
	}
}
