.panel {
	border: 1px solid #aaa;
	border-radius:4px;
	padding:12px;
	margin-bottom:10px;

	.panel-title {
		font-weight:bold;
	}
	.panel-body {

	}
}