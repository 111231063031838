
.overviewHeader, #upload-buttons {
	display: flex;

	@media all and (max-width: $smallScreen) {
		display: block;
	}

	flex-direction: row;

	#overviewCounter {
		padding: 7px 0;
		flex-grow: 1;
	}
	
} 

.cards-small {
	.card {
		min-width: 250px !important;
	}
}

.card-img-container {
	@include rounded;
	
	&::after {
	    content: "";
	    display: block;
	}

	.card-icon-img {
		@include rounded;
		position: relative;
		object-fit: cover;
	}
}

.card-icon {
	@include rounded;
	text-align: center;
}

.cards {	
	display: flex;
	flex-flow: row wrap;
	
	.cursor {
		// height:0;
	}
	
	.card {
		.card-img-container {
			display: inherit;
		}
		
		.card-icon {
			.fa-stack {
				display: flex;
			
				i:nth-child(2) {
					font-size: 2em;
				}
			}
		}
	}

	.card {
		@include no-select;
		object {
			-webkit-user-drag: none;
		}
		cursor: pointer;
		min-width: 400px;

		display: flex;
		flex: 1 0 0;
		margin: 5px;
		padding: 10px;
		border-radius: 5px;
		font-size: 0.9em;
		
		.card-left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			.card-img-container {
				width:60px;
				height:60px;
				
				.card-img {
					width:60px;
					height:60px;
				}
			}
			
			.card-icon {
				width:60px;
				height:60px;
				text-align: center;
				i {
					line-height: 60px;
				}

			}
			
			.card-status {
				margin-top: 10px;
				border-radius: 5px;
				color:white;
				text-align:center;
				font-size:0.6em;
				line-height: 2em;
			}
		}
	
		.card-right {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;

			padding-left: 10px;
			.card-header {
				font-size:1.2em;
				padding-bottom: 5px;
			}
			.card-main {
				flex-grow: 1;
				font-size:0.8em;
				ul {
					line-height: 1.3em;
				}
			}
			.card-sub { 
				display: flex;
				padding-top: 5px;
 				
			}
		}
	
		&.hidden {
			padding: 0px 10px 0px 10px;
			cursor: auto;
			background: none;
			height: 0px;
			opacity: 0;	
		}
	}	
	
	&.cards-thumb {
		justify-content: space-between;
		
		.card-thumb {			
			.drop-status {
				display: none;
				
				&.visible {
					display: block;
				}
			}

			.card-top {
				width: 120px;
				height: 120px;
				@include rounded;
				
				.card-icon {
					width:120px;
					height:120px;
					text-align: center;
					i {
						font-size: 4.2em;
						line-height: 120px;
						width: 120px;						
					}

					.fa-stack {				
						i:nth-child(2) {
							font-size: 2em;
						}
					}

				}
				
				.card-icon-img {
					width:120px;
					height:120px;
				}
								
			}
			
			.card-bottom {
				.progress {
					display: block;
					opacity: 0;
					width: 100%;
					margin: 5px 0;
					border-radius: 4px;
					
					.progress-inner {
						border-radius: 4px;
						height: 20px;
						display: block;
					}
				}
			}
		}
	}

	&.cards-style-card {
		.card-thumb {
			flex: 1 0 0;
			min-width: 400px;
			
			@media all and (max-width: $smallScreen) {
				min-width: 200px;
			}
			
			flex-direction: row;
			padding-bottom: 15px;
			
			.card-top {
				width: 60px;
				height: 60px;
				background: gray;
				margin-bottom: 25px;
				
				.card-img-container {
					width:60px;
					height:60px;
					
					.card-icon {
						width: 60px;
						height: 60px;
						text-align: center;
						i {
							font-size: 2.1em;
							line-height: 60px;
							width: 60px;
						}

						.fa-stack {				
							i:nth-child(2) {
								font-size: 1em;
							}
						}

					}
					
					.card-icon-img {
						width: 60px;
						height: 60px;
					}
				}
				
				.card-status, .drop-status {					
					text-align: center;
					font-size: 0.6em;
					line-height: 2;
					border-radius: 5px;
					margin-top: 10px;
				
					&.visible {
						display: block;
						
						i {
							display: none;
						}
					}
				
					.card-status-icon {
						display: none;
					}	
				}
			}
			
			.card-bottom {
				justify-content: space-between;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 10px;
				overflow: hidden;
				
				.card-title {
					font-size: 1.2em;
					padding-bottom: 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}


				.card-tags {
					
					.tag-with-name {
						display: inline-block;
					}
					
					.tag-without-name {
						display: none;
					}
				}
				

			}
			.card-subtitle {
				margin-bottom:5px;
			}
			.card-info {
				font-size: .8em;
				flex-grow: 1;
			}			
		}
	}
	
	&.cards-style-thumb {
		.card-thumb {
			flex: 0 0 0;
			min-width: 140px;
			background: transparent !important;
			flex-direction: column;
						
			.card-top {
				position: relative;
				
				.card-icon-img {
					position: absolute;
				}
				
				.card-status, .drop-status {
					width: 26px;
					height: 26px;
					
					text-align: center;
					position: absolute;
					right: 0;
					top: 0;
					font-size: 1.2em;
					line-height: 21px;
					border-radius: 13px;
					border: 2px solid #fff;	
								
					.card-status-icon {
						padding-top: 0.14em;
						padding-left: 0.05em;
						
						&.card-icon-progress {
							font-size: 0.8em;
						}
					}
				}
			}
			
			.card-bottom {
				.card-tags {
					margin-top: 5px;
					text-align: center;
					
					.tag-with-name {
						display: none;
					}
					
					.tag-without-name {
						display: inline-block;
					}
				}

			}
		
			.card-title {
				padding-top: 10px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 0.8em;
				line-height: 1.2em;
			}
			.card-subtitle {
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 0.8em;
			}
			
			.card-info, .card-footer {
				display: none;
			}	
			
			.card-status span {
				display: none;
			}
			
			&.card-progress {							
				.card-top {	
					.card-img-container {
						padding: 5px 0 0 5px;
						
						.card-icon, .card-icon-img {
							width: 110px;
							height: 110px;
							i {
								position: relative;
								left: -5px;
								top: -5px;
							}
						} 		
					}
				}
			}
		}
	}
	
	&.cards-style-list {
		.card {
			margin: 5px 0;
		}

		.card-thumb {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			min-width: 100%;
			 
			.card-bottom {
				flex-basis: 100%;
				flex-grow: 1;
				display: flex;
				flex-direction: row;
				flex: 1;
				align-items: center;
						
				.card-title {
					//flex-grow: 4;
					margin: 0 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 1.2em;
				}
				
				.card-info {
					//flex-grow: 2;
					align-items: center;
				}

				.card-tags {
					
					.tag-with-name {
						display: inline-block;
					}
					
					.tag-without-name {
						display: none;
					}
				}

			}
					
			.card-bottom > div, .card-info > div {
				padding: 0 5px;
				flex-basis: 100%;
				flex-grow: 0;
				flex-direction: row;
				flex: 1;
				
				.progress {
					padding: 0 0;
				}
			}

				
			.card-top {
				flex-grow: 0;
				position: relative;
				width: 30px;
				height: 30px;
				background: gray;
				
				.card-icon {
					width:30px;
					height:30px;
					text-align: center;
					i {
						font-size: 1.1em;
						line-height: 30px;
						width: 30px;
					}

					.fa-stack {				
						i:nth-child(2) {
							font-size: 0.2em;
						}
					}

				}
				
				.card-icon-img {
					width:30px;
					height:30px;
				}
			
				.card-status, .drop-status {
					width: 14px;
					height: 14px;
					
					text-align: center;
					position: absolute;
					right: -4px;
					top: -3px;
					font-size: 0.1em;
					line-height: 12px;
					border-radius: 50%;
					border: 1px solid #fff;	

					.card-status-icon {
						padding-top: 0.09em;
						padding-left: 0.1em;
					}
				}
			}
			

			.card-status span {
				display: none;
			}

		}
	}
	
}

.card-buttonbar {
	text-align: right;
}

#removeSelectionSquare {
	border: 2px solid black;
	position: absolute;
	display: block;
	z-index: 99999;
}

#multiRemove {
	@include rounded;
	display: none;
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 999;
	cursor: pointer;
	
	&.visible {
		display: block;
	}
	
	i {
		font-size: 4em;
		padding: 0.4em;
	}
	
	.notification-badge {
		display: none;
		position: absolute;
		right: 2px;
		border-radius: 50%;
	}
}
